/* LostGameModal.css */

/* Define a CSS animation for the modal */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
  opacity: 0; /* Start with zero opacity */
  animation: modalFadeIn 0.3s ease-in-out forwards; /* Apply the animation */
}

.popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.popup p {
  margin-bottom: 15px;
  font-size: 18px;
}

.popup b {
  font-size: 24px;
  color: #007BFF; /* Blue color for the score */
}

.popup button {
  padding: 10px 20px;
  background-color: #007BFF; /* Blue color for the button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
  transition: background-color 0.3s ease-in-out;
}

.popup button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.popup button.secondary {
  background-color: #555;
}

.popup button.secondary:hover {
  background-color: #333;
}

.popup button.share-button {
  background-color: #1DA1F2; /* Twitter blue color for the share button */
}

.popup button.share-button:hover {
  background-color: #0C7CBF; /* Darker blue on hover for share button */
}
