.header-container {
  height: 60px;
  background-color: #fff;
  position: fixed;
  top: 0px;
  z-index: 111;
  width: 100%;
}
.header-container .branding {
  display: flex;
  width: 100%;
  height: 60px;
  max-width: 1000px;
  margin: 0 auto;
  border-bottom: 1px solid #eb1e00;
}
.header-container .branding .branding__logos {
  display: block;
  flex-shrink:0;
}
.header-container .branding .branding__logos .branding__logo {
    max-height: 30px;
    display: inline-block;
    vertical-align: middle;
}
.header-container .branding .branding__logos .branding__logo-link {
  display: block;
  padding: 0 40px;
  border-bottom: 2px solid #eb1e00;
  line-height: 58px;
  max-width: none;
  padding-left: 20px;
}
.header-container .branding .branding__fill {
  display: flex;
  order: 0;
  width: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
.header-container .branding .branding__brand {
  display: block;
  flex-shrink:0;
  margin: 10px 0;
  padding: 8px 20px;
  border-left: 1px solid #ddd;
}

.header-container .branding .branding__brand .branding__brand-image {
  height: auto;
  max-width: 100%;
  max-height: 24px;
}
