.countdown-timer {
    color: white;
    position: absolute;
    bottom: 20; /* Set to the bottom of the page */
    right: 50; /* Set to the right of the page */
    transform: translateX(-50%);
    z-index: 1; /* Ensure the title is above other elements */

    background-color: black; /* Updated to black */
    color: rgba(255, 255, 255, 0.7); /* Faded white text */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.7; /* Adjust for the desired fade effect */
    transition: opacity 0.3s ease;
    font-size: 2em; /* Set the initial font size */
    transition: font-size 0.3s ease; /* Add a transition for smooth scaling */
  }

  @media only screen and (max-width: 800px) {
    .countdown-timer {
      top: 95%;
      left: 50%;
      white-space: nowrap; /* Prevent text from wrapping to the next line */
    }
  }

  .green-text {
    color: green;
  }

  .red-text {
    color: red;
  }

  .yellow-text {
    color: yellow;
  }