.score {
  color: black;
  font-size: 30px;
  position: absolute;
  font-weight: bold;
  bottom: 0; /* Set to the bottom of the page */
  right: 0; /* Set to the right of the page */
  transform: translateX(-50%);
  z-index: 1; /* Ensure the title is above other elements */
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
  padding: 10px; /* Add some padding for better visibility */
}

@media only screen and (max-width: 800px) {
  .score {
    font-size: 18px;
    bottom: auto; /* Remove the bottom property */
    top: 70px;
    left: 35%;
    color: black;
    font-weight: bold;
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
  }
  .score p {
    margin: 0;
  }
}

