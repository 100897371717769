body {
    font-family: 'Arial', sans-serif;
    background-color: #f2f2f2;
    margin: 0;
    overflow: hidden;
  }
  
  .homepage-container {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Set a high z-index value */
  }
  
  .homepage-image {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Set a lower z-index value than homepage-container */
    filter: brightness(70%);
  }
  
  .homepage-content {
    text-align: center;
    color: white;
    font-size: 30px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1; /* Ensure the title is above other elements */
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black; 
  }
  
  .homepage-content h1 {
    font-size: 50px;
    margin-top: 20px;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  }
  
  @media only screen and (max-width: 800px) {
    .homepage-content h1 {
      font-size: 20px;
      margin-top: 0;
    }
  }
  
  .homepage-content label {
    color: white;
    margin-right: 10px;
  }
  
  .homepage-content select {
    width: 100px; /* Adjust the width as needed */
    padding: 5px;
    font-size: 16px;
  }

  @media only screen and (max-width: 600px) {
    .homepage-image {
      content: url('../../../../public/images/quiz_cover.png');
    }
  }
  
  @media only screen and (min-width: 601px) {
    .homepage-image {
      content: url('../../../../public/images/homepage_nba.jpg');
    }
  }
  
  .homepage-content button {
    color: white;
    background-color: black;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 28px;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.9;
    transition: opacity 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }  

  .homepage-content button:hover {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.05); /* Scale the button slightly on hover without moving */
  }
