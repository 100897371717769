body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.full-screen-container {
  display: flex;
  height: 100vh;
  padding-top: 59px;
}

.app-title {
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; /* Ensure the title is above other elements */
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;

}

@media only screen and (max-width: 800px) {
  .app-title {
    color: black;
    font-size: 18px;
    position: absolute;
    top: 60px;
    left: 16%;
    font-weight: bold;
    transform: translateX(-50%);
    z-index: 1; /* Ensure the title is above other elements */
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
    text-align: left;
    margin: 10px 0;
  }
}

.quiz-question {
  text-align: center;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; /* Ensure the title is above other elements */
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
  color: black;
  font-size: 50px;
}

@media only screen and (max-width: 800px) {
  .quiz-question {
    position: absolute;
    right: -40%;
    max-width: 90%;
    color: black;
    font-size: 30px; /* Adjust the font size for mobile screens */
    top: 45%;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
  }
}

.image-container div {
  cursor: pointer;
}

.image-container {
  display: flex;
  flex-direction: row-reverse;
}

.image {
  object-fit: cover; /* or 'contain' depending on your preference */
  width: 105%;
  height: 110%;
}

@media screen and (max-width: 800px) {
  .image-container {
    flex-direction: column; /* Change to side-by-side layout for larger screens */
  }
  .image {
    width: 105%;
    height: 100%;
  }
}

.app-header {
  color: #ffffff;
  text-align: center;
  padding: 20px;
}

.button-option1-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.button-option2-container {
  position: absolute;
  top: 50%; /* Center vertically */
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%); /* Center vertically */
  z-index: 1; /* Ensure the title is above other elements */

}

.button-container button {
  margin-right: 10px;
}
