/* AnswerOptionButton.css */

.answer-option-button-left {
  background-color: black;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 28px;
  position: absolute;
  top: 80%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Include transform in the transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.answer-option-button-right {
  background-color: black;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 28px;
  position: absolute;
  top: 80%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Include transform in the transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.answer-option-button:hover {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.05); /* Scale the button slightly on hover without moving */
}

@media only screen and (max-width: 800px) {
  .answer-option-button {
    padding: 5px 10px; /* Adjust the padding for mobile screens */
    font-size: 18px; /* Adjust the font size for mobile screens */
  }

  .answer-option-button-left {
    position: absolute;
    padding: 5px 5px;
    top: 35%;
    left: 85%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.6;
    font-size: 20px;
    opacity: .8;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Include transform in the transition */
  }
  
  .answer-option-button-right {
    color: white;
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 20px;
    position: absolute;
    top: 85%;
    left: 85%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.8;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Include transform in the transition */
  }
}
